<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Working Shift</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'working-shifts'}">
                        <span>Working Shifts</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:submit.prevent="createWorkingShift()">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>

                        <div class="col-md-9">
                            <input
                                v-model="working_shifts.name"
                                @change="textTitleCase($event, 'name')"
                                type="text"
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Start time
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-timepicker 
                                v-model="working_shifts.start_time" 
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                autocomplete="off"
                                locale="en"
                            >              
                            </b-form-timepicker>
                        </div>
                    </div>

                     <div class="form-group row">
                        <label class="col-md-3">
                            End time
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                         <div class="col-md-9">
                            <b-form-timepicker 
                                v-model="working_shifts.end_time" 
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                autocomplete="off"
                                locale="en"
                            >              
                            </b-form-timepicker>
                        </div>
                    </div>

                    <div class="form-group row">
                            <label class="col-md-3">
                                Night Shift
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-group >
                                    <b-form-radio-group
                                        id="radio-slots-1"
                                        v-model="working_shifts.night_shift"
                                        :options="night_shift_options"
                                        name="radio-options-slots"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="createWorkingShift()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            working_shifts:{
                name: "",
                start_time:"",
                end_time:"",
                night_shift:"0",
            },
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            night_shift_options: [
                { value: "1", text: "True" },
                { value: "0", text: "False" }
            ],
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_WORKING_SHIFTS");
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.working_shifts[key] = this.strTitleCase(event.target.value);
        },

        createWorkingShift() {
            this.startProgressBar();
            const data = this.deleteEmptyKeys(this.working_shifts);
            this.axios
                .post(this.$api.create_working_shift, data, this.getAuthHeaders())
                .then(() => {
                    this.updateProgressBar(true);
                    this.api_error = "";
                    this.$router.push({name: 'working-shifts'});
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>
